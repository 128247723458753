export const APP_KEY = 'MOBILE_HUTECH';
export const APP_ID = 8;

const PATH_TRANSLOCO_BADGE_STATUS = 'features.noiQuyLaoDong.share.badge_status';
export const TranslocoPathBadgeStatus = {
  doi_duyet: PATH_TRANSLOCO_BADGE_STATUS + '.doi_duyet',
  da_duyet: PATH_TRANSLOCO_BADGE_STATUS + '.da_duyet',
  da_cau_hinh: PATH_TRANSLOCO_BADGE_STATUS + '.da_cau_hinh',
  chua_cau_hinh: PATH_TRANSLOCO_BADGE_STATUS + '.chua_cau_hinh',
  kich_hoat: PATH_TRANSLOCO_BADGE_STATUS + '.kich_hoat',
  chua_kich_hoat: PATH_TRANSLOCO_BADGE_STATUS + '.chua_kich_hoat',
  dang_dung: PATH_TRANSLOCO_BADGE_STATUS + '.dang_dung',
  chua_dung: PATH_TRANSLOCO_BADGE_STATUS + '.chua_dung',
  nhap: PATH_TRANSLOCO_BADGE_STATUS + '.nhap',
  khong_duyet: PATH_TRANSLOCO_BADGE_STATUS + '.khong_duyet',
  chua_xu_ly: PATH_TRANSLOCO_BADGE_STATUS + '.chua_xu_ly',
  dang_xu_ly: PATH_TRANSLOCO_BADGE_STATUS + '.dang_xu_ly',
  da_xu_ly: PATH_TRANSLOCO_BADGE_STATUS + '.da_xu_ly',
  chap_nhan: PATH_TRANSLOCO_BADGE_STATUS + '.chap_nhan',
  khong_chap_nhan: PATH_TRANSLOCO_BADGE_STATUS + '.khong_chap_nhan',
  chua_giai_trinh: PATH_TRANSLOCO_BADGE_STATUS + '.chua_giai_trinh',
  qua_thoi_han: PATH_TRANSLOCO_BADGE_STATUS + '.qua_thoi_han',
  khong_vi_pham: PATH_TRANSLOCO_BADGE_STATUS + '.khong_vi_pham',
  vi_pham: PATH_TRANSLOCO_BADGE_STATUS + '.vi_pham',
  da_giai_trinh: PATH_TRANSLOCO_BADGE_STATUS + '.da_giai_trinh',
};

export const TAB_CA_NHAN = {
  OVERVIEW: 'overview',
  NGHI_PHEP: 'nghi_phep',
  HOC_TAP_CONG_TAC: 'hoc_tap_cong_tac',
  THOI_GIAN_LAM_VIEC: 'thoi_gian_lam_viec',
  SAI_PHAM: 'sai_pham',
};

export const FORMATTIME = {
  DDMMYYYYHHmm: 'DD/MM/YYYY HH:mm',
  YYYYMMDD: 'YYYY/MM/DD',
  DDMMYYYY: 'DD/MM/YYYY',
  YYYYMMDDHHmm: 'YYYY-MM-DD HH:mm:ss',
};

export const URL_API = {
  CONG_TAC_HOC_TAP: 'http://localhost:5000/upload',
};

export const KEY_EMIT_HIDEN = {
  CREATED: 'CREATED',
  HUY_DANG_KY: 'HUY_DANG_KY',
};

export enum LoaiPhieu {
  PHEP = 'p',
  CONG_TAC = 'ct',
  HAU_SAN = 'ts',
  BUOI_LAM_VIEC = 'm',
}

export enum StatusPhieu {
  NHAP = 'nhap',
  DOI_DUYET = 'doi_duyet',
  DA_DUYET = 'da_duyet',
  KHONG_DUYET = 'khong_duyet',
  HET_HIEU_LUC = 'het_hieu_luc',
  DA_HUY = 'da_huy',
}

export enum FileNameDangKy {
  CONG_TAC_HOC_TAP = 'CONG_TAC_HOC_TAP',
  PHEP = 'PHEP',
  THAI_SAN = 'THAI_SAN',
  BUOI_LAM_VIEC = 'BUOI_LAM_VIEC',
}

export enum CurrentStatusUser {
  CHAP_THUAN = 'CHAP_THUAN',
  TU_CHOI = 'TU_CHOI',
  DANG_CHO_DUYET = 'DANG_CHO_DUYET',
}

export const TRANG_THAI_REQUEST_THOI_GIAN_LAM_VIEC = [
  { value: 'nhap', ten: '(Nháp)', color: '' },
  {
    value: 'doi_duyet',
    ten: 'Đợi duyệt',
    color: 'px-2 py-1 text-center status--yellow',
  },
  {
    value: 'khong_duyet',
    ten: 'Không duyệt',
    color: 'px-2 py-1 text-center status--red',
  },
  {
    value: 'da_duyet',
    ten: 'Đã duyệt',
    color: 'px-2 py-1 text-center status--green',
  },
  {
    value: 'het_hieu_luc',
    ten: 'Hết hiệu lực',
    color: 'px-2 py-1 text-center status--default',
  },
  {
    value: 'da_huy',
    ten: 'Đã hủy',
    color: 'px-2 py-1 text-center status--red',
  },
];

export const BO_LOC_THOI_GIAN_LAM_VIEC = [
  {
    key: 'all',
    text: 'Tất cả',
  },
  {
    key: 'doi_duyet',
    text: 'Chờ duyệt',
  },
  {
    key: 'da_duyet',
    text: 'Đã duyệt',
  },
  {
    key: 'khong_duyet',
    text: 'Từ chối',
  },
];

// Danh sách dành cho router modal
export const TYPE_ROUTER = {
  ca_nhan: 'ca-nhan',
  quan_ly: 'quan-ly',
  quan_ly_tra_cuu_ghi_nhan_sai_pham: 'quan-ly-tra-cuu-ghi-nhan-sai-pham',
};

// Danh sách trạng thái đăng ký hộ
export const DANG_KY_HO_STATUS = {
  DANG_XU_LY: 'dang_xu_ly',
  DA_XU_LY: 'da_xu_ly',
  NHAP: 'nhap',
};

// Change this const to change toolbar display options of pdf viewer
export const REGISTER_PDF_VIEWER_IFRAME = {
  ENABLE_FIND: false,
  ENABLE_OPEN_FILE: false,
  ENABLE_VIEW_BOOKMARK: false,
  CUSTOM_STYLE:
    'min-height: 98vh; border-radius: 4px; border-color: var(--gray); border-width: 1px',
};

export const REGISTER_PDF_VIEWER = {
  SHOW_PAGING_BUTTON: true,
  SHOW_DOWNLOAD_BUTTON: true,
  SHOW_PRINT_BUTTON: true,
  SHOW_ZOOM_BUTTONS: true,
  TEXT_LAYER: false,
  ZOOM: 'auto',
  HEIGHT: 'auto',
};

// Link hướng dẫn khi người dùng đăng nhập không được
export const HELPER_LOGIN_FAILURE_LINK = {
  HUTECH_URL: 'https://qlcntt.hutech.edu.vn/ho-tro?tieu_de=tai%20khoan',
  UEF_URL: 'https://support.uef.edu.vn/forgot-password',
  ROYAL_URL: 'http://support.royal.edu.vn/forgot-password',
};
